import { galleryData } from '../../data/gallery'
import Animate from '../Animate/Animate'
import ImageGallery from '../ImageGallery/ImageGallery'
import './Gallery.css'
function Gallery() {
  return (
    <div className='bg-dark-2 py-5'>
      <div className="container">
        <Animate animate="fade-up">
          <h3 className='text-light m-0 fw-bold'>Gallery</h3>
        </Animate>
        <Animate animate="fade-up" data-aos-delay="500">
          <p className='text-white mb-5 mt-3'>We are privileged to have curated a stunning collection of captivating images that showcase the essence of our programmes. Our gallery is the perfect place to immerse yourself in a visual feast, enabling you to get a comprehensive understanding of what we do. Witness joy, cheer, love, enthusiasm and a zest for life from our participants, for whose joy we exist. When hygiene meets fun, the combination is bound to be a deadly one!</p>
        </Animate>
        <ImageGallery images={galleryData} />
      </div>
    </div>
  )
}

export default Gallery