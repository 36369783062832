import React, { useCallback, useRef, useState } from 'react';
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import ReactPlayer from 'react-player';
import { Autoplay, EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { MissionSlider } from '../../data/sliders';
import Animate from '../Animate/Animate';
import './Mission.scss';

const Mission = () => {
  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const prevSlide = useCallback(() => {
    swiperRef.current?.swiper.slidePrev();
  }, [swiperRef]);
  const nextSlide = useCallback(() => {
    swiperRef.current?.swiper.slideNext();
  }, [swiperRef]);

  return (
    <div className="bg-dark-2 py-5 px-3 missions">
      <Animate animate="zoom-in">
        <div className="container">
          <div className="d-lg-flex text-white align-items-center ps-lg-5 ms-lg-4 mb-5">
            <div className="col">
              <h3>SIFEL '1 India' Mission</h3>
              <p className="missions-details m-0">
                To promote a healthier and more joyous India, by creating hygiene awareness amongst large sections of society, through games, fun and entertainment activities.
              </p>
            </div>
            {/* <div className="col">
              <Link className="links justify-content-end" to="/">
                To know more
                <ImPlay2 className="links-icon" />
              </Link>
            </div> */}
          </div>
        </div>
      </Animate>
      <Swiper
        autoplay={{
          delay: 20000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false
        }}
        ref={swiperRef}
        pagination={false}
        slidesPerView={'auto'}
        spaceBetween={25}
        freeMode={true}
        centeredSlides={true}
        breakpoints={{
          "1200": {
            slidesPerView: 2,
            spaceBetween: 25,
          },
        }}
        effect={'coverflow'}
        grabCursor={true}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 20,
          modifier: 1,
          slideShadows: true,
        }}
        slideToClickedSlide={true}
        modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
        onActiveIndexChange={(element) => setActiveIndex(element.activeIndex)}
        autoplayDisableOnInteraction={true}
      >
        {MissionSlider.map((item, index) => (
          <SwiperSlide className="missions" key={index}
            autoPlay={true}
            watchSlidesProgress={true}>
            <div className="bg-dark-500">
              <ReactPlayer
                loop
                key={item.id}
                url={item.src}
                controls={true}
                muted={true}
                playing={activeIndex === index}
                width="100%" height={window.innerWidth > 992 ? 520 : 320}
                config={{
                  youtube: {
                    playerVars: {
                      showinfo: 0,
                      modestbranding: 1,
                      rel: 0,
                      iv_load_policy: 3,
                    }

                  }
                }}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className='container mt-5'>
        <div className="ps-lg-5 ms-lg-4 mb-5">
          <button className='mx-2 btn btn-outline-light rounded-pill' onClick={() => prevSlide()}><FaAngleLeft /></button>
          <button className='mx-2 btn btn-outline-light rounded-pill' onClick={() => nextSlide()}><FaAngleRight /></button>
        </div>
      </div>
      {/* <center className="missions mt-5">
        <Link className="more-missions" to="/gallery">
          <span className="more-missions-text">More on our mission</span>
          <span className="more-missions-icon"><AiOutlinePlus /></span>
        </Link>
      </center> */}
    </div>
  );
};

export default Mission;
