import Banner from '../../Components/Banner/Banner'
import Donate from '../../Components/Donate/Donate'
import Gallery from '../../Components/Gallery/Gallery'
import Mission from '../../Components/Mission/Mission'
import Reach from '../../Components/Reach/Reach'

function Home() {
  return (
    <>
      <Banner />
      <Mission />
      <Reach />
      <Gallery />
      <hr className='border-bottom border-secondary container mx-auto' />
      <Donate />
      {/* <Satisfaction /> */}
    </>
  )
}

export default Home