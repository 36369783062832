// import { Gallery } from "react-grid-gallery";
import { ReactPhotoCollage } from "react-photo-collage";

export default function ImageGallery({ images }) {
  const isDesktop = window.innerWidth > 992

  const setting = {
    width: "auto",
    height: ["350px", "500px", "350px", "350px", "500px", "350px", "500px", "350px"],
    layout: [3, 2, 3, 2, 3, 2, 3, 2,],
    photos: images,
    showNumOfRemainingPhotos: false,
  };
  return (
    <div>
      {
        isDesktop ? <ReactPhotoCollage {...setting} /> : (
          <div>
            {images.map(item => <img src={item.source} width='100%' className="m-1" />)}
          </div>
        )
      }

    </div>
  );
}