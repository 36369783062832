import { useContext } from 'react'
import ScrollCounter from '../../Components/ScrollCounter/ScrollCounter'
import { FormContextApi } from '../../Layout'


function AboutNote() {
    const form = useContext(FormContextApi)
    return (
        <div><div className="card col-lg-10 rounded-left-0 bg-dark text-light mt-4">
            <div className="card-body p-4 pt-5">
                <div className="row align-items-center">
                    <div className="col-md-5 text-center">
                        <h3 className='text-white fw-bold text-center mb-lg-5'>About SIFEL</h3>
                        <img src={require('../../Assets/Images/about.png')} alt="" className='w-75 my-3' />
                    </div>
                    <div className="col-md-7 small fw-light text-justify">
                        <div className="col-xl-10 ms-auto">
                            <div className='d-flex flex-wrap gap-4 border-bottom border-black'>
                                <div className="cards p-0">
                                    <div className='fw-light'>Melas</div>
                                    <h3 className='card-title mb-1'>
                                        <ScrollCounter initialValue={0} finalValue={200} />
                                        <span>+</span>
                                    </h3>
                                    <span className='badge-line'></span>
                                </div>
                                <div className="cards p-0">
                                    <div className='fw-light'>Villages</div>
                                    <h3 className='card-title mb-1'>
                                        <ScrollCounter initialValue={0} finalValue={50} />
                                        <span>+</span>
                                    </h3>
                                    <span className='badge-line'></span>
                                </div>
                                <div className="cards p-0">
                                    <div className='fw-light'>Volunteers</div>
                                    <h3 className='card-title mb-1'>
                                        <ScrollCounter initialValue={0} finalValue={100} />
                                        <span>+</span>
                                    </h3>
                                    <span className='badge-line'></span>
                                </div>
                                <div className="cards p-0">
                                    <div className='fw-light'>States</div>
                                    <h3 className='card-title mb-1'>
                                        <ScrollCounter initialValue={0} finalValue={7} />
                                        <span>+</span>
                                    </h3>
                                    <span className='badge-line'></span>
                                </div>
                                <div className="cards p-0">
                                    <div className='fw-light'>Central jails</div>
                                    <h3 className='card-title mb-1'>
                                        <ScrollCounter initialValue={0} finalValue={10} />
                                        <span>+</span>
                                    </h3>
                                    <span className='badge-line'></span>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <p>Chennai-based SIFEL (SM Foundation for Equality, Fraternity and Liberty) is the brainchild of a retired military Officer, Squadron Leader SM. SIFEL is a “for purpose” organization that works in rural India and central jails, in order to spread awareness regarding handwash hygiene through various games, sports and entertainment programmes.</p>
                            <p>The organization conducts “Hygiene through fun” melas on a consistent basis, at several villages in TN, UP, Bihar, MP, Jharkhand, Odisha, West Bengal and Chattisgarh, in addition to several jails in the country.</p>
                            <p>The melas have a series of fun, lively and interactive games for people of all ages from 1 to 90! Various games such as tug of war, lemon and spoon, ball pyramid, antakshari, dancing competitions and so on, are held with great fervour, with the villagers also being served tea and snacks. The whole event gives a festive look, like a typical village mela.</p>
                            <p>The most important aspect of the mela is normally around mid-time, when the event coordinator explains the ideal steps to be followed for handwashing along with its importance. Moreover, volunteers from the crowd are also roped in and asked to give demonstrations on the same.</p>
                            <p className='mb-0'>Driven by the motto “seeing divinity in the joy of people”, SIFEL believes that celebration needs no reason, and intends to spread hygiene awareness in jails and socially backward areas across India, through team building games and sports on a regular basis, in all regions where it has operations.</p>
                        </div>
                        {/* <div className="reach-title justify-content-end">
                            <img src={require('../../Assets/Images/icon-groups.png')} alt="india_map" width={70} />
                            <div className="vr bg-light mx-2"></div>
                            <Link className='links mx-2' onClick={() => form.setForm(true)} >
                                <span>Join with us</span>
                                <ImPlay2 className='links-icon' />
                            </Link>
                        </div> */}
                    </div>
                </div>
            </div>
        </div></div>
    )
}

export default AboutNote