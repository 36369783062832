import "../../Common/Common.scss";
import Animate from "../Animate/Animate";
import "../Reach/Reach.scss";
import ScrollCounter from "../ScrollCounter/ScrollCounter";

function Reach() {
  return (
    <div className="bg-dark-2 reach">
      <div className="container">
        <Animate animate="fade-down">
          <div className="reach-title">
            <h3 className='text-light m-0 fw-bold' >Our Reach</h3>
            {/* <Link className='links mx-2'>
              <span>Join with us</span>
              <ImPlay2 className='links-icon' />
            </Link> */}
          </div>
        </Animate>
      </div>
      <div className="my-4">
        <div className="row justify-content-center gap-3 mx-0">
          <Animate animate="flip-left" data-aos-delay="100" className="col-md-6 col-lg-4 col-xl-2 p-0">
            <div className="cards reach-card bg-dark-light">
              <h3 className='card-title'>
                <ScrollCounter initialValue={0} finalValue={200} />
                <span>+</span>
              </h3>
              <h3 className='card-subtitle'>Melas</h3>
              <p className='card-detail m-0' >
                We are proud to share with you the immense success and impact our events have had on promoting good hygiene practices and improving the overall well-being of communities.
              </p>
            </div>
          </Animate>
          <Animate animate="flip-right" data-aos-delay="500" className="col-md-6 col-lg-4 col-xl-2 p-0">
            <div className="cards reach-card bg-dark-light">
              <h3 className='card-title'>
                <ScrollCounter initialValue={0} finalValue={50} />
                <span>+</span>
              </h3>
              <h3 className='card-subtitle'>Villages </h3>
              <p className='card-detail m-0' >We are pleased to share that we have successfully conducted 'Hygiene through fun' melas in more than 50+ villages, impacting over 20,000 villagers across the country. </p>
            </div>
          </Animate>
          <Animate animate="flip-left" data-aos-delay="1000" className="col-md-6 col-lg-4 col-xl-2 p-0">
            <div className="cards reach-card bg-dark-light">
              <h3 className='card-title'>
                <ScrollCounter initialValue={0} finalValue={7} />
                <span>+</span>
              </h3>
              <h3 className='card-subtitle'>States</h3>
              <p className='card-detail m-0' >Our hygiene melas are designed to spread awareness about the importance of maintaining good hand hygiene practices, through fun games and entertainment activities.</p>
            </div>
          </Animate>
          <Animate animate="flip-left" data-aos-delay="1000" className="col-md-6 col-lg-4 col-xl-2 p-0">
            <div className="cards reach-card bg-dark-light">
              <h3 className='card-title'>
                <ScrollCounter initialValue={0} finalValue={100} />
                <span>+</span>
              </h3>
              <h3 className='card-subtitle'>Volunteers</h3>
              <p className='card-detail m-0' >Our volunteers are our prized possessions. We are blessed to have a highly enthusiastic team of individuals with “service above self” as their motto.</p>
            </div>
          </Animate>
          <Animate animate="flip-right" data-aos-delay="1500" className="col-md-6 col-lg-4 col-xl-2 p-0">
            <div className="cards reach-card bg-dark-light">
              <h3 className='card-title'>
                <ScrollCounter initialValue={0} finalValue={10} />
                <span>+</span>
              </h3>
              <h3 className='card-subtitle'>Central Jails </h3>
              <p className='card-detail m-0'> There is no place where joy is more welcome than in jails. We currently work in some of the largest jails in the country, and are humbled to acknowledge that our central jails hygiene mela programmes are a treat to watch, relish, participate and enjoy.</p>
            </div>
          </Animate>
        </div>
      </div>
    </div>
  )
}

export default Reach