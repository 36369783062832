import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
// import 'aos/dist/aos.css';
import React, { createContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { Outlet } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import ContactForm from './Components/ContactForm/ContactForm';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';

Fancybox.bind("[data-fancybox]");

// AOS.init({
//   disable: window.innerWidth < 992,
// });

export const FormContextApi = createContext();

function Layout() {
  const [form, setForm] = useState(false);
  return (
    <FormContextApi.Provider value={{ form, setForm }}>
      <Header />
      <div className='min-vh-100 bg-dark-2'>
        <Outlet />
        <Modal show={form} size="md" centered>
          <Modal.Body >
            <button type="button" onClick={() => setForm(false)} className="btn-modal-close shadow" data-bs-dismiss="modal" aria-label="Close">
              <AiOutlineClose />
            </button>
            <h1 className="modal-title text-center fs-5 fw-bold text-dark" id="ContactUsLabel">Contact US</h1>
            <ContactForm />
          </Modal.Body>
        </Modal>
      </div>
      <Footer />
    </FormContextApi.Provider>
  )
}

export default Layout