import React from 'react'

function MediaFinding() {
    const news = [
        // {
        //     href: "https://up-todaynews.com/",
        //     image: require('../../Assets/clients/client (1).png')
        // },
        {
            href: "https://newsdastak.in/2301/",
            image: require('../../Assets/clients/client (2).png')
        },
        {
            href: "https://www.youtube.com/watch?v=aUA0WPpt4E4",
            image: require('../../Assets/clients/client (3).png')
        },
        {
            href: "https://www.youtube.com/watch?v=-nMd1VbeStI",
            image: require('../../Assets/clients/client (4).png')
        },
        {
            href: "https://www.youtube.com/watch?v=MJKko_VPq14",
            image: require('../../Assets/clients/client (5).png')
        },
        {
            href: "https://www.youtube.com/watch?v=Rk3C6pN8Hu4",
            image: require('../../Assets/clients/client (6).png')
        },
        {
            href: "https://shuru.co.in/post/f8a322b7-7da5-4cb0-a026-9091db9acaaa",
            image: require('../../Assets/clients/client (7).png')
        },
        {
            href: "https://www.youtube.com/watch?v=uHj1yR4ojsE&t=44s",
            image: require('../../Assets/clients/client (8).png')
        },
        {
            href: "https://www.youtube.com/watch?v=SgGp1ZKZTVU&t=35s",
            image: require('../../Assets/clients/client (9).png')
        },
        {
            href: "https://www.thehindu.com/news/cities/chennai/de-addiction-centre-traditional-games-inaugurated-in-puzhal-central-prison/article67384489.ece/amp/",
            image: require('../../Assets/clients/client (10).png')
        },
        {
            href: "https://newsindiaexpress.co.in/33712",
            image: require('../../Assets/clients/client (11).png')
        },
        {
            href: "https://chhattisgarh.co/news-detail/312390",
            image: require('../../Assets/clients/client (12).png')
        },
        {
            href: "https://chhattisgarh.co/news-detail/312514",
            image: require('../../Assets/clients/client (12).png')
        },
        {
            href: "https://mpcgvisiontv.blogspot.com/2024/05/pithora.html",
            image: require('../../Assets/clients/client (14).png')
        },
        {
            href: "https://chhattisgarh.co/news-detail/312723",
            image: require('../../Assets/clients/client (15).png')
        },
        {
            href: "https://mpcgvisiontv.blogspot.com/2024/05/pithora_8.html",
            image: require('../../Assets/clients/client (13).png')
        },
        {
            href: "https://chhattisgarh.co/news-detail/312844",
            image: require('../../Assets/clients/client (12).png')
        },
        {
            href: "https://chhattisgarh.co/news-detail/312976#google_vignette",
            image: require('../../Assets/clients/client (12).png')
        },
        {
            href: "https://chhattisgarh.co/news-detail/313082",
            image: require('../../Assets/clients/client (12).png')
        },
        {
            href: "https://chhattisgarh.co/news-detail/313116",
            image: require('../../Assets/clients/client (12).png')
        }
    ]
    return (
        <div>
            <div className="container">
                <h3 className='border-bottom border-secondary py-3 text-white my-4'>SIFEL in Print & Electronic media</h3>
            </div>
            <div className="offset-md-3 col-md-7 ">
                <div className="d-flex flex-wrap gap-lg-5 gap-2 justify-content-end justify-content-center justify-content-lg-end">
                    {
                        news.map((item, i) => (
                            <a href={item.href} target='_blank' key={i} className='bg-light rounded-4 d-flex align-items-center justify-content-center hover-bg-success logo-icon'>
                                <img src={item.image} className='w-100' />
                            </a>
                        ))
                    }
                    {
                        [...Array(16)].map((a, i) => (
                            <div key={i} className='bg-light rounded-4 overflow-hidden d-flex align-items-center justify-content-center hover-bg-success logo-icon'>
                                <img src={require(`../../Assets/news/news (${i + 1}).jpeg`)} data-fancybox="news" className='w-100' />
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default MediaFinding