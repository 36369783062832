const MissionSlider = [
    {
        id: 1,
        type: 'video',
        src: 'https://www.youtube.com/embed/9nSzrbSynqU',
    },
    {
        id: 2,
        type: 'video',
        src: 'https://www.youtube.com/embed/E1_PdF1mtM0',
    },
    {
        id: 3,
        type: 'video',
        src: 'https://www.youtube.com/embed/wB_o3wPeVFc',
    },
    {
        id: 4,
        type: 'video',
        src: 'https://www.youtube.com/embed/UcEW4afO-BE',
    },
    {
        id: 5,
        type: 'video',
        src: 'https://www.youtube.com/embed/fMSL_swzDTA',
    },
    {
        id: 6,
        type: 'video',
        src: 'https://www.youtube.com/embed/AePXvAh-RsU',
    },
    {
        id: 7,
        type: 'video',
        src: 'https://www.youtube.com/embed/7kUXOR9ZEXc',
    },
];


export { MissionSlider };

