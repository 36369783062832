
import { BiSolidPhoneCall } from "react-icons/bi";
import { IoIosMail } from "react-icons/io";
import './ContactForm.css'

function ContactForm() {
    return (
        <div className='d-lg-flex align-items-center justify-content-center gap-3 mx-auto'>
            <a href="tel:918754536260" target="_blank" className="contact-link d-block p-4  text-center text-dark rounded"> <BiSolidPhoneCall size={50} /><div>+91 87545 36260</div> </a>
            <a href="mailto:sm@sifelindia.org" target="_blank" className="contact-link d-block p-4  text-center text-dark rounded">  <IoIosMail size={50} /><div>sm@sifelindia.org</div></a>
        </div>
    )
}

export default ContactForm
// import axios from 'axios';
// import { useContext, useState } from 'react'
// import Swal from 'sweetalert2';
// import { FormContextApi } from '../../Layout';

// export default function ContactForm({ size = null, className = '', inputClass = '', setIsSubmit = false }) {
    //     const form = useContext(FormContextApi)
    //     const initialValues = { name: "", email: "", mobile: "" };
    //     const [formValues, setFormValues] = useState(initialValues)
    //     const [formErrors, setFormErrors] = useState({});
    //     const [Loading, setLoading] = useState(false);
    
//     const formHandler = (e) => {
//         e.preventDefault()
//         setFormErrors(validate(formValues))

//         if (Object.keys(formErrors).length === 0) {
//             setLoading(true)
//             axios.post('https://ejnarstudios.com/ej-mailer/api/send-mail', formValues).then(response => {
//                 if (response.data.status) {
//                     if (setIsSubmit) {
//                         setIsSubmit(true)
//                     } else {
//                         Swal.fire({
//                             title: 'Thank you!',
//                             html: `<h4 className="mb-3">for reaching out to us!</h4>`,
//                             // showConfirmButton:false
//                         })
//                     }
//                 } else {
//                     Swal.fire({
//                         title: 'Whoo!',
//                         text: response.data.message,
//                         icon: 'warning',
//                         confirmButtonText: 'Okay'
//                     })
//                 }
//             }).finally(() => { form.setForm(false); setLoading(false) })
//         }
//     }

//     const inputHandler = (e) => {
//         const { name, value } = e.target
//         setFormValues({ ...formValues, [name]: value })
//     }

//     const validate = (values) => {
//         const errors = {};
//         const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
//         const mobileRegex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;

//         if (!values.name) {
//             errors.name = "Name is required!";
//         }
//         if (!values.email) {
//             errors.email = "Email is required!";
//         } else if (!emailRegex.test(values.email)) {
//             errors.email = "This is not a valid email format!";
//         }
//         if (!mobileRegex.test(values.mobile)) {
//             errors.mobile = "Invalid mobile number!";
//         }
//         return errors;
//     }
//     return (
//         <form onSubmit={formHandler} className={`inputs ${className}`}>
//             <div>
//                 <label className={size === 'sm' ? 'small ' : ' mb-1'}>Name <sup>*</sup></label>
//                 {formErrors.name ? <p className='small text-danger'>{formErrors.name}</p> : ""}
//                 <input className={(size === 'sm' ? 'form-control-sm form-control' : 'form-control') + " " + inputClass} placeholder='Enter your name' name='name' onChange={inputHandler} type="text" required />
//             </div>
//             <div>
//                 <label className={size === 'sm' ? 'small ' : ' mb-1 mt-3'}>Mobile<sup>*</sup></label>
//                 {formErrors.mobile ? <p className='small text-danger'>{formErrors.mobile}</p> : ""}
//                 <input className={(size === 'sm' ? 'form-control-sm form-control' : 'form-control') + " " + inputClass} name='mobile' placeholder='Enter your mobile number' onChange={inputHandler} type="text" required />
//             </div>
//             <div>
//                 <label className={size === 'sm' ? 'small ' : ' mb-1 mt-3'}>Email<sup>*</sup></label>
//                 {formErrors.email ? <p className='small text-danger'>{formErrors.email}</p> : ""}
//                 <input className={(size === 'sm' ? 'form-control-sm form-control' : 'form-control') + " " + inputClass} name='email' onChange={inputHandler} placeholder='Enter your email' type="text" required />
//             </div>
//             <div>
//                 <label className={size === 'sm' ? 'small' : 'mb-2 mt-3'}>Message</label>
//                 <textarea className={(size === 'sm' ? 'form-control-sm form-control' : 'form-control') + " " + inputClass} name='message' placeholder='Enter your message' onChange={inputHandler} rows={4} />
//             </div>
//             <div className='text-center pt-2'>
//                 {
//                     Loading ?
//                         <button type='button' disabled className="btn  px-4 rounded-pill btn-secondary">Connecting ...</button>
//                         :
//                         <button type='submit' className="btn  px-4 rounded-pill btn-primary shadow bg-gradient mt-1">Submit</button>
//                 }
//             </div>
//         </form>
//     )
// }
