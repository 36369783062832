import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { AiFillHeart } from 'react-icons/ai';
import { IoIosCloseCircle } from "react-icons/io";
import { QRCode } from 'react-qrcode-logo';

function QrForm() {
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm();

    const [amount, setAmount] = useState(0)
    const [info, setInfo] = useState("0")

    const onSubmit = (data) => {
        const infoText = `Donation by Indian | '${data.method}'.`
        if (window.innerWidth < 922) {
            const upiPaymentLink = `upi://pay?pa=sifel@sbi&pn=Intellemo&tn=${encodeURIComponent(infoText)}&am=${data.amount}.00`;
            window.location.href = upiPaymentLink;
        } else {
            setAmount(data.amount);
            setInfo(infoText)
        }
    };

    const amountData = [
        500,
        1000,
        1500,
        2000,
        3000,
        5000,
        7000,
        10000,
        15000,
    ]
    return (
        <div className='position-relative'>
            <div className='card-header'>Amount Details</div>
            {
                amount > 0 ?
                    <div className="qr-box">
                        <div className="position-relative">
                            <IoIosCloseCircle onClick={() => setAmount(0)} size={40} className='close-button' />
                            <QRCode
                                value={`upi://pay?pa=sifel@sbi&pn=Intellemo&tn=${info}&am=${amount}`}
                                size="280"
                                logoImage="https://res.cloudinary.com/dcjmxozzs/image/upload/v1702704118/sifel/logo/logo_feyamu.png"
                                logoWidth="100"
                                logoHeight="50"
                                logoOpacity="0.7"
                            />
                        </div>
                    </div>
                    : null
            }
            <form onSubmit={handleSubmit(onSubmit)} className="card-body h-100">
                {/* Select your Amount */}
                <div className="py-2 select-amount">
                    <p className="m-0 fw-bold fs-4 position-relative">Select your Amount {errors.amount && <p className="error-message">* {errors.amount.message}</p>}</p>
                    <div className="d-flex flex-wrap gap-3 pt-3">
                        {
                            amountData.map((amt) => (
                                <label htmlFor={"amount" + amt} className="checkbox-button" key={amt}>
                                    <input
                                        type="radio"
                                        id={"amount" + amt}
                                        name="amount"
                                        value={amt}
                                        {...register('amount', { required: 'Amount is required' })}
                                    />
                                    <span>₹.{amt}</span>
                                </label>
                            ))
                        }
                    </div>
                    <p className="m-0 my-3 fw-bold fs-6 position-relative">For Custom Amount (or) use UPI: <a href="upi://pay?pa=sifel@sbi&pn=Intellemo" target='_blank'>Donate to sifel@sbi</a></p>
                    <input
                        type="number"
                        className='form-control bg-black text-white'
                        placeholder='Enter Your Amount'
                        min={1}
                        {...register('amount')}
                    />
                </div>

                {/* ... other checkboxes and inputs ... */}

                {/* Submit button */}
                <div className='text-end mt-3'>
                    <Button type="submit" className='btn btn-primary'>
                        <AiFillHeart className='me-2' /> Donate Now
                    </Button>
                    {/* <div className='mt-2'>
                        <img src={require('../../Assets/Images/payments.png')} alt="visa_logo" />
                    </div>
                    <p >we accept all major payment methods</p> */}
                </div>
            </form>
        </div>
    )
}

export default QrForm