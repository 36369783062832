import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Layout from './Layout'
import Home from './Pages/Home/Home'
import About from './Pages/About/About'
import ScrollToTop from './Components/ScrollToTop'  
import Donate from './Components/Donate/Donate'
import Gallery from './Components/Gallery/Gallery'

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Layout />} >
          <Route index element={<Home />} />
          <Route path='/about-us' element={<About />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/donate' element={<Donate />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App