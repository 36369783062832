export const galleryData =
    [
        {
            source: 'https://res.cloudinary.com/dcjmxozzs/image/upload/v1703699099/sifel/gallery/img_26.jpg'
        },
        {
            source: 'https://res.cloudinary.com/dcjmxozzs/image/upload/v1703699030/sifel/gallery/img_1_xxm52x.png'
        },
        {
            source: 'https://res.cloudinary.com/dcjmxozzs/image/upload/v1703699025/sifel/gallery/img_22_izeztk.jpg'
        },
        {
            source: 'https://res.cloudinary.com/dcjmxozzs/image/upload/v1703699016/sifel/gallery/img_34_z5s7wd.jpg'
        },
        {
            source: 'https://res.cloudinary.com/dcjmxozzs/image/upload/v1703699015/sifel/gallery/img_36_uukfjq.jpg'
        },
        {
            source: 'https://res.cloudinary.com/dcjmxozzs/image/upload/v1703699015/sifel/gallery/img_37_wlvupa.jpg'
        },
        {
            source: 'https://res.cloudinary.com/dcjmxozzs/image/upload/v1703699015/sifel/gallery/img_30_zzuigc.jpg'
        },
        {
            source: 'https://res.cloudinary.com/dcjmxozzs/image/upload/v1703699014/sifel/gallery/img_35_toc7k5.jpg'
        },
        {
            source: 'https://res.cloudinary.com/dcjmxozzs/image/upload/v1703699012/sifel/gallery/img_32_yf82e5.jpg'
        },
        {
            source: 'https://res.cloudinary.com/dcjmxozzs/image/upload/v1703699010/sifel/gallery/img_33_cna415.jpg'
        },
        {
            source: 'https://res.cloudinary.com/dcjmxozzs/image/upload/v1703699009/sifel/gallery/img_31_ot6qxr.jpg'
        },
        {
            source: 'https://res.cloudinary.com/dcjmxozzs/image/upload/v1703699007/sifel/gallery/img_20_mrup2f.jpg'
        },
        {
            source: 'https://res.cloudinary.com/dcjmxozzs/image/upload/v1703699006/sifel/gallery/img_28_jiuxy6.jpg'
        },
        {
            source: 'https://res.cloudinary.com/dcjmxozzs/image/upload/v1703699006/sifel/gallery/img_29_mhnlb3.jpg'
        },
        {
            source: 'https://res.cloudinary.com/dcjmxozzs/image/upload/v1703699003/sifel/gallery/img_39_oiff2j.jpg'
        },
        {
            source: 'https://res.cloudinary.com/dcjmxozzs/image/upload/v1703699001/sifel/gallery/img_21_bktgjm.jpg'
        },
        {
            source: 'https://res.cloudinary.com/dcjmxozzs/image/upload/v1703699000/sifel/gallery/img_19_ozmzeo.jpg'
        },
        {
            source: 'https://res.cloudinary.com/dcjmxozzs/image/upload/v1703699000/sifel/gallery/img_27_ygkpge.jpg'
        },
        {
            source: 'https://res.cloudinary.com/dcjmxozzs/image/upload/v1703698999/sifel/gallery/img_24_epf6nt.jpg'
        }
    ]
