import React, { useCallback, useRef } from 'react';
import { FaCaretRight } from 'react-icons/fa6';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import "../Banner/Banner.scss";

function Banner() {
  const swiperRef = useRef(null);
  const screenWidth = window.innerWidth
  const nextSlide = useCallback(() => {
    const swiperInstance = swiperRef.current?.swiper;
    // Check if the current slide is the last one
    if (swiperInstance && swiperInstance.isEnd) {
      // If it is, go back to the first slide
      swiperInstance.slideTo(0);
    } else {
      // Otherwise, go to the next slide
      swiperInstance && swiperInstance.slideNext();
    }
  }, [swiperRef]);

  return (
    <div>
      <Swiper
        loop={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false
        }}
        ref={swiperRef}
        grabCursor={true}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        className="mySwiper"
        modules={[Pagination, Navigation, Autoplay]}
      >
        {
          [...Array(16)].map((k, i) => (
            <SwiperSlide key={i} >
              <div className="banner-container">
                <img src={require(`../../Assets/banners/${screenWidth > 992 ? 'lg' : 'sm'}/banner-${screenWidth > 992 ? 'lg' : 'sm'} (${i + 1}).jpg`)} alt="SIFEL '1 India' Mission" className='banner-image' />
                <div className="banner-items">
                  <h1 className='banner-title'>SIFEL '1 India' Mission</h1>
                  <h4 className='banner-content'>Come, join the movement towards a happier and healthier India!</h4>
                </div>
              </div>
            </SwiperSlide>
          ))
        }
        <div className='hero-banner-navigation'>
          <button onClick={() => nextSlide()}><FaCaretRight size={20} /></button>
        </div>
      </Swiper>
    </div>
  )
}

export default Banner