import Animate from "../Animate/Animate";
import "../Donate/Donate.scss";
import QrForm from "./QrForm";


function Donate() {
    return (
        <div className='bg-dark-2 donate'>
            <div className="container col-md-9 mx-auto">
                <Animate animate='fade-up'>
                    <h4 className='text-secondary fw-bold text-center'><span className="text-sifel">I am eager to contribute</span> towards a healthier, happier India</h4>
                </Animate>
                <div className="card mt-5 bg-black shadow-dark">
                    <div className="row donate-card h-100 align-items-center">
                        <Animate animate='zoom-in' className="col-md-4 donate-image bg-dark h-100">
                            <img src="https://res.cloudinary.com/dcjmxozzs/image/upload/v1703701803/sifel/others/1_6_toq394.jpg" alt="donate_image" />
                        </Animate>
                        <Animate animate='fade-up' className="col-md-8 donate-payment bg-dark h-100">
                            <QrForm />
                        </Animate>
                    </div>
                </div>
                <p className='p-2 my-4 text-light text-justify'>All donations to SIFEL are eligible for tax exemption under Section 80G. Donations are completely channelized towards mela programmes and campaigns, while administrative costs are self funded.</p>
            </div>
        </div>
    )
}

export default Donate