import React, { useContext, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { AiFillHeart } from 'react-icons/ai';
import { FiMenu, FiX } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import "../../Common/Common.scss";
import { FormContextApi } from '../../Layout';
import "../Header/Header.scss";

function Header() {
  const form = useContext(FormContextApi);
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar expand="lg" collapseOnSelect={true} className='navbar bg-black' onToggle={() => setExpanded(!expanded)}>
      <Container>
        <Navbar.Brand as={Link} to='/' className='fw-bold fs-3 text-light' >
          <img src="https://res.cloudinary.com/dcjmxozzs/image/upload/v1702704118/sifel/logo/logo_feyamu.png" alt="brand_logo" className='brand-logo' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" className='text-white'>
          {expanded ? <FiX size={30} /> : <FiMenu size={30} />}
        </Navbar.Toggle>
        <Navbar.Collapse id="navbarScroll">
          <Nav className="ms-auto my-2 my-lg-0" navbarScroll >
            <Nav.Link className='nav-links' eventKey={'Home'} as={Link} to='/' >Home</Nav.Link>
            <Nav.Link className='nav-links' eventKey={'About'} as={Link} to='/about-us'>About Us</Nav.Link>
            <Nav.Link className='nav-links' eventKey={'Gallery'} as={Link} to='/gallery'>Gallery</Nav.Link>
            <Nav.Link className='nav-links' eventKey={'Vlogs'} as={'a'} href='https://www.youtube.com/@sifelindia389' target='_blank'>Vlogs</Nav.Link>
            <Nav.Link className='nav-links' eventKey={'Contact'} onClick={() => form.setForm(true)} >Contact</Nav.Link>
            <Nav.Link className='m-3 btn d-flex align-items-center btn-primary text-center justify-content-center' eventKey={'DonateNow'} as={Link} to='/donate'  >
              <AiFillHeart className='me-2' /> Donate Now
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Header