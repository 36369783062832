import AboutNote from './AboutNote'
import MediaFinding from './MediaFinding'

const About = () => {
  return (
    <div className="pb-4">
      <img src={require('../../Assets/Images/about-banner.png')} alt="" className="w-100" />
      <AboutNote />
      <MediaFinding />
    </div>
  )
}

export default About