import React, { useRef, useEffect, useState } from 'react';

const ScrollCounter = ({ initialValue, finalValue }) => {
  const [count, setCount] = useState(initialValue);
  const counterRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // When the element is in the viewport, start counting up
            let start = initialValue;
            const step = (finalValue - initialValue) / 50; // Adjust this value for desired speed
            const increment = () => {
              if (start < finalValue) {
                setCount(start);
                start += step;
                requestAnimationFrame(increment);
              } else {
                setCount(finalValue);
              }
            };
            increment();
            observer.unobserve(counterRef.current);
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.2, // Adjust this threshold as needed
      }
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, [initialValue, finalValue]);

  return <div className="animated-counter" ref={counterRef}>{Math.round(count)}</div>;
};

export default ScrollCounter;